import React, { PropsWithChildren } from 'react';
import classes from "./NavBar.module.sass";

export default (props: PropsWithChildren): JSX.Element => {
  const children = React.Children.toArray(props.children).filter(x => React.isValidElement(x)) as JSX.Element[];
  return (
    <nav className={`${classes.navbar} sm:py-2 pe-6 sm:ps-4 overflow-hidden`}>
      <div className="h-full max-w-full flex gap-5">
        <div className="w-1/3 min-w-fit flex gap-2 md:gap-5 xl:gap-10">
          {children.filter(x => !x.props.end && !x.props.center)}
        </div>
        <div className="py-2 sm:py-0 flex flex-auto justify-end gap-2 md:gap-4 xl:gap-5">
          <div className="xl:w-1/2 min-w-fit flex xl:justify-center gap-2 md:gap-4 xl:gap-5">
            {children.filter(x => x.props.center)}
          </div>
          <div className="xl:w-1/2 flex justify-end gap-2 md:gap-4 xl:gap-5 overflow-hidden">
            {children.filter(x => x.props.end && !x.props.center)}
          </div>
        </div>
      </div>
    </nav>
  );
}
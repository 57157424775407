import React, {useEffect, useState} from 'react';
import './App.module.sass';
import useGlobalState, {WithGlobalState} from "./contexts/GlobalContext";
import axios from "axios";
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MapPage from './pages/MapPage';
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from "./routes/PrivateRoute";
import LoadingScreen from "./components/LoadingScreen/LoadingScreen";
import classes from './App.module.sass';
import HomePage from "./pages/HomePage";
import AuthPage from "./pages/AuthPage";
import { getCookie, setCookie } from "./services/CookiesService";
const verifyTokenAPIURL = "https://j4g4ad57h0.execute-api.eu-north-1.amazonaws.com/prod/verify"
const config = {
  headers: {
    'x-api-key': 'tXm34puc5n6mP0JrNgRVZ3rD8P396WKx3vepLVSf',
    'Access-Control-Allow-Origin': '*'
  }
};

const App = () => {
  const {global, updateGlobalState} = useGlobalState();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!loaded) {
      updateGlobalState("isLoading", true);
      updateGlobalState("isAuthorized", false);
      console.log('ASS');
      const user = sessionStorage.getItem("user") || getCookie('user');
      if (user) {
        const token = sessionStorage.getItem('jwt-token') || getCookie('jwt-token');
        if (token) {
          axios.post(verifyTokenAPIURL, { username: user, token: token }, config).then(response => {
            sessionStorage.setItem("user", response.data.user);
            sessionStorage.setItem("jwt-token", response.data.token);
            document.cookie = setCookie("user", response.data.user, { expires: 1 });
            document.cookie = setCookie("jwt-token", response.data.token, { expires: 1 });
            updateGlobalState("isAuthorized", true);
            updateGlobalState("isLoading", false);
            console.log('ASS2');
          }).catch(() => {
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("jwt-token");
            document.cookie = setCookie("user", '', { expires: 0 });
            document.cookie = setCookie("jwt-token", '', { expires: 0 })
            updateGlobalState("isLoading", false);
            console.log('ASS3');
          })
        } else updateGlobalState("isLoading", false);
      } else updateGlobalState("isLoading", false);
      setLoaded(true);
    }
  }, [updateGlobalState]);

  return (
    <div className={`theme-${global.theme}`}>
      <BrowserRouter>
        <div className={`${classes.content} w-screen h-screen`}>
          {global.isLoading && <LoadingScreen/>}
          {!global.isLoading && <Switch>
            <PublicRoute exact path="/" component={HomePage}/>
            <PublicRoute path="/auth" component={AuthPage}/>
            <PrivateRoute path="/tempest" component={MapPage}/>
            <Route path='*' component={LoadingScreen}/>
          </Switch>}
        </div>
      </BrowserRouter>
    </div>
  );
};

export default () =>
  <WithGlobalState>
    <App/>
  </WithGlobalState>
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useControl, Marker, MarkerProps, ControlPosition } from 'react-map-gl';
import MapboxGeocoder, { GeocoderOptions } from '@mapbox/mapbox-gl-geocoder';
import useGlobalState from "../../contexts/GlobalContext";

// @ts-ignore
type GeocoderControlProps = Omit<GeocoderOptions, 'accessToken' | 'mapboxgl' | 'marker'> & {
  mapboxAccessToken: string;
  marker?: boolean | Omit<MarkerProps, 'longitude' | 'latitude'>;
  position: ControlPosition;
  onLoading?: (e: object) => void;
  onResults?: (e: object) => void;
  onResult?: (e: object) => void;
  onError?: (e: object) => void;
};

const GeocoderControl: React.FC<GeocoderControlProps> = (props) => {
  const { global, updateGlobalState } = useGlobalState();

  // @ts-ignore
  const geocoder = useControl<MapboxGeocoder>(
    () => {
      const ctrl = new MapboxGeocoder({
        ...props,
        marker: false,
        accessToken: props.mapboxAccessToken,
      });

      if (props.onLoading) ctrl.on('loading', props.onLoading);
      if (props.onResults) ctrl.on('results', props.onResults);
      if (props.onResult) { // @ts-ignore
        ctrl.on('result', (evt) => {
          props.onResult?.(evt);
          const { result } = evt;
          const location =
            result?.center || (result?.geometry?.type === 'Point' && result.geometry.coordinates);

          if (location && props.marker) {
            // @ts-ignore
            console.log('props.onResult', global.visitedPlaces)
            const newPlace = {
              longitude: location[0] as number,
              latitude: location[1] as number,
              description: (result["text_en-US"] || result.text) as string,
            };
            updateGlobalState('visitedPlaces', newPlace);
          }
        });
      }
      if (props.onError) ctrl.on('error', props.onError);

      return ctrl;
    },
    { position: props.position }
  );

  useEffect(() => {
    console.log("Visited places updated:", global.visitedPlaces);

    // @ts-ignore
    if (!geocoder._map) return;

    const settings: { key: string; setter: (value: any) => void; getter: () => any }[] = [
      { key: 'proximity', setter: (v) => geocoder.setProximity(v), getter: () => geocoder.getProximity() },
      { key: 'render', setter: (v) => geocoder.setRenderFunction(v), getter: () => geocoder.getRenderFunction() },
      { key: 'language', setter: (v) => geocoder.setLanguage(v), getter: () => geocoder.getLanguage() },
      { key: 'zoom', setter: (v) => geocoder.setZoom(v), getter: () => geocoder.getZoom() },
      { key: 'flyTo', setter: (v) => geocoder.setFlyTo(v), getter: () => geocoder.getFlyTo() },
      { key: 'placeholder', setter: (v) => geocoder.setPlaceholder(v), getter: () => geocoder.getPlaceholder() },
      { key: 'countries', setter: (v) => geocoder.setCountries(v), getter: () => geocoder.getCountries() },
      { key: 'types', setter: (v) => geocoder.setTypes(v), getter: () => geocoder.getTypes() },
      { key: 'minLength', setter: (v) => geocoder.setMinLength(v), getter: () => geocoder.getMinLength() },
      { key: 'limit', setter: (v) => geocoder.setLimit(v), getter: () => geocoder.getLimit() },
      { key: 'filter', setter: (v) => geocoder.setFilter(v), getter: () => geocoder.getFilter() },
      { key: 'origin', setter: (v) => geocoder.setOrigin(v), getter: () => geocoder.getOrigin() },
    ];

    settings.forEach(({ key, setter, getter }) => {
      const value = (props as any)[key];
      if (value !== undefined && getter() !== value) {
        setter(value);
      }
    });
  }, [geocoder, props, global.visitedPlaces]);
  return  <></>
};

const noop = () => {
};

GeocoderControl.defaultProps = {
  marker: true,
  onLoading: noop,
  onResults: noop,
  onResult: noop,
  onError: noop,
};

export default GeocoderControl;

/*
(
    <>
      {global.visitedPlaces.map(({ longitude, latitude, description }, index) => {
        console.log("Rendering marker:", longitude, latitude, description);
        return (
          <div key={`marker-${index}`}>
            <Marker anchor='bottom' pitchAlignment='viewport'
                    latitude={latitude} longitude={longitude}>
              <div className="relative mb-16">
                <div className="
                  absolute top-2 right-7 flex items-center justify-center
                  w-10 h-10 rounded-full bg-red-500 animate-pulse
                ">
                  <div className="w-4 h-4 rounded-full bg-white"></div>
                </div>
                <div className="
                  absolute top-2 left-1/2 px-2 py-1 rounded-xl shadow-2xl bg-gray-300
                  text-xl font-bold font-sans text-gray-800 text-nowrap animate-pulse
                ">
                  {description}
                </div>
              </div>
              <img src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png"
                   alt="Weather" width={50} height={50} className='animate-bounce'/>
            </Marker>
          </div>
        );
      })}
    </>
  );
 */
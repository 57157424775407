import Layout from "../components/Layout";
import Header from "../components/Header";
import NavBar from "../components/NavBar/NavBar";
import ByteDawnIcon from "../components/ByteDawnIcon/ByteDawnIcon";
import AnimatedLink from "../components/AnimatedLink/AnimatedLink";
import ThemeSwitch from "../components/ThemeSwitch/ThemeSwitch";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import LandingHero from "../components/LandingHero/LandingHero";
import Footer from "../components/Footer/Footer";
import FooterSection from "../components/FooterSection/FooterSection";

export default () =>
  <Layout title='Welcome!'>
    <Header>
      <NavBar>
        <ByteDawnIcon/>
        <AnimatedLink to="/auth" center>Sign in</AnimatedLink>
        <ThemeSwitch end/>
      </NavBar>
    </Header>
    <ScrollableContainer>
      <main>
        <LandingHero/>
      </main>
      <Footer>
        <FooterSection title='About Tempest' paragraph>
          🗨️ Tempest is a next level React/SASS/Lambda mapping service hosted on AWS
        </FooterSection>
        <FooterSection title='Services'>
          <AnimatedLink to='/auth'>Sign in</AnimatedLink>
        </FooterSection>
      </Footer>
    </ScrollableContainer>
  </Layout>
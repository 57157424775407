import Layout from "../components/Layout";
import Header from "../components/Header";
import TitleBar from "../components/TitleBar/TitleBar";
import NavBar from "../components/NavBar/NavBar";
import ByteDawnIcon from "../components/ByteDawnIcon/ByteDawnIcon";
import NavButton from "../components/NavButton/NavButton";
import DropdownMenu from "../components/DropdownMenu/DropdownMenu";
import DropdownItem from "../components/DropdownItem/DropdownItem";
import ThemeSwitch from "../components/ThemeSwitch/ThemeSwitch";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import Map from "../components/WeatherMap/WeatherMap";
import useGlobalState from "../contexts/GlobalContext";
import { useHistory } from "react-router-dom";
import { setCookie } from "../services/CookiesService";

export default () => {
  const { updateGlobalState } = useGlobalState();
  let history = useHistory();

  const handleSignout = () => {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("jwt-token");
    document.cookie = setCookie("user", '', { expires: 0 });
    document.cookie = setCookie("jwt-token", '', { expires: 0 });
    updateGlobalState("isAuthorized", false);
    history.push('/');
  }

  const handleClear = () => {
    updateGlobalState('visitedPlaces', null);
  }

  return (
    <Layout title={`Tempest - ${sessionStorage.getItem("user")}`}>
      <Header>
        <TitleBar/>
        <NavBar>
          <ByteDawnIcon/>
          <NavButton icon='🧹' center onClick={handleClear}/>
          <NavButton icon='🇬🇧' center>
            <DropdownMenu>
              <DropdownItem>English</DropdownItem>
              <DropdownItem>Deutsch</DropdownItem>
              <DropdownItem>Русский</DropdownItem>
              <DropdownItem>Українська</DropdownItem>
            </DropdownMenu>
          </NavButton>
          <NavButton icon='⎘' center largerText onClick={handleSignout}/>
          <ThemeSwitch end/>
        </NavBar>
      </Header>
      <ScrollableContainer>
        <main>
          <Map/>
        </main>
      </ScrollableContainer>
    </Layout>
  );
}
import Button from "../Button/Button";
import classes from "./LandingHero.module.sass";

export default () =>
  <div className="w-full mt-16 p-16 pb-32">
    <h1 className={`
      ${classes.title} w-full sm:w-9/12 md:w-2/3 lg:w-1/2 md:mt-2 
      lg:mt-10 text-4xl font-bold text-center md:text-left
    `}>
      ֍ Welcome to Tempest!
    </h1>
    <p className={`${classes.description} w-full sm:w-4/5 md:w-4/5 lg:w-3/5 xl:w-2/3 mt-6 text-center sm:text-left`}>
      Tempest was built on Typescript React with SASS styles and a Lambda backend written in JS that interacts
      with DynamoDB instance. Website is hosted on AWS through S3 storage and traffic is managed through
      Route-53/Cloudfront. Mapbox is the service provider. Log in account to jump into your best mapping experience
    </p>
    <Button className='mt-10 w-full sm:w-9/12 md:w-1/3 lg:w-fit lg:px-20 animate-bounce' text='🗺' onClick='/tempest'/>
  </div>
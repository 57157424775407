import { useHistory } from 'react-router-dom';
import classes from "./Button.module.sass";

interface ButtonProps {
  className: string,
  text: any,
  onClick?: (() => void) | string
}

export default ({ className, text, onClick }: ButtonProps): JSX.Element => {
  const history = useHistory();
  const handleClick = () =>
    onClick ? (typeof onClick === 'string' ? history.push(onClick) : onClick()) : '';
  return (
    <button onClick={handleClick} className={`${className} ${classes.button} p-2 rounded`}>
      {text}
    </button>
  )
};
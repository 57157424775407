import React, {useEffect, useRef, useState} from 'react';
import Map, {GeolocateControl, Marker, NavigationControl} from 'react-map-gl';
import useGlobalState, {Themes} from "../../contexts/GlobalContext";
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import GeocoderControl from "./GeocoderControl";

const mapboxAPIToken = 'pk.eyJ1IjoibmltY290IiwiYSI6ImNtMDVmcmpyNzBocjIyanF4NDVzM2kwYWUifQ.Vk23Zeuc6xh3u1vyY5XO5Q';

export default () => {
  const {global} = useGlobalState();
  const viewport = {
    latitude: 55.25,
    longitude: 24.45,
    zoom: 6
  };
  const [userLocation, setUserLocation] = useState(null);
  const [markerCoords, setMarkerCoords] = useState(null);
  const geolocateControlRef = useRef(null);
  const mapRef = useRef(null);

  const handleSuccess = (position) => {
    const {latitude, longitude} = position.coords;
    setUserLocation({latitude, longitude});
    mapRef.current.flyTo({
      center: [longitude, latitude],
      zoom: 12
    })
  };

  const handleMapLoad = () => {
    if (mapRef.current) {
      console.log("Map loaded", mapRef.current);
    }
    if (geolocateControlRef.current && typeof geolocateControlRef.current.trigger === 'function')
      geolocateControlRef.current?.trigger();
    else if ('geolocation' in navigator)
      navigator.geolocation.getCurrentPosition(handleSuccess, () => {}, {enableHighAccuracy: true});
  };

  /* useEffect(() => {
    if ('geolocation' in navigator) {
      const watchId = navigator.geolocation.watchPosition(handleSuccess, () => {}, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      });
      return () => navigator.geolocation.clearWatch(watchId);
    }
  }, []); */

  // const handleMapClick = (event) => {
  //   setMarkerCoords({latitude: event.lngLat.lat, longitude: event.lngLat.lng});
  // };

  useEffect(() => {}, [global.visitedPlaces])

  return (
    <Map
      initialViewState={viewport} mapboxAccessToken={mapboxAPIToken} ref={mapRef}
      style={{ width: "100%", height: "100%" }}
      mapStyle={
        global.theme === Themes.Light
          ? 'mapbox://styles/nimcot/cm0nl1btt00da01qy339m9ph3'
          : 'mapbox://styles/mapbox/navigation-night-v1'
      }
      fog={{
        'range': [0.8, 8],
        'color': global.theme === Themes.Light ? "#a2c4d8" : '#323f57',
        'horizon-blend': 0.3,
        'high-color': global.theme === Themes.Light ? "#ffffff" : '#000000',
        'space-color': global.theme === Themes.Light ? "#ece8e1" : '#000000',
        'star-intensity': 0.35
      }}
      projection='globe' reuseMaps={true} renderWorldCopies={false} onLoad={handleMapLoad} // onClick={handleMapClick}
    >
      <GeolocateControl
        position='bottom-left' trackUserLocation={true} showUserLocation={true}
        positionOptions={{enableHighAccuracy: true}} ref={geolocateControlRef}
        style={{
          background: global.theme === Themes.Light
            ? 'rgba(230,225,216,0.25)'
            : 'rgba(40,40,43,0.65)'
        }}
      />
      <GeocoderControl mapboxAccessToken={mapboxAPIToken} position="top-left"/>
      {global.theme === Themes.Light &&
        <NavigationControl
          position='bottom-right' showZoom={true} showCompass={true} visualizePitch={false}
          style={{background: 'rgba(236,232,225,0.68)'}}
        />
      }
      { // userLocation && (
        //   <Marker latitude={userLocation.latitude} longitude={userLocation.longitude} pitchAlignment='map'>
        //     <div className='p-[7px] rounded-full border-[3px] border-white bg-sky-500'></div>
        //   </Marker>
        // )
      }
      { // markerCoords && (
        //   <Marker anchor='bottom' pitchAlignment='viewport'
        //           latitude={markerCoords.latitude} longitude={markerCoords.longitude}>
        //     <div className="relative mb-16">
        //       <div className="
        //         absolute top-2 right-7 flex items-center justify-center
        //         w-10 h-10 rounded-full bg-red-500 animate-pulse
        //       ">
        //         <div className="w-4 h-4 rounded-full bg-white"></div>
        //       </div>
        //       <div className="
        //         absolute top-2 left-1/2 px-2 py-1 rounded-xl shadow-2xl bg-gray-300
        //         text-xl font-bold font-sans text-gray-800 text-nowrap animate-pulse
        //       ">
        //         Marker fsfs fsfs fs
        //       </div>
        //     </div>
        //     <img src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png"
        //          alt="Weather" width={50} height={50} className='animate-bounce'/>
        //   </Marker>
        // )
      }
      {global.visitedPlaces.map(({ longitude, latitude, description }, index) => {
        console.log('RE-RENDERED');
        return (<Marker key={`marker-${index}`} anchor='bottom' pitchAlignment='viewport' latitude={latitude} longitude={longitude}>
          <div className="relative mb-16">
            <div className="
              absolute top-2 right-7 flex items-center justify-center
              w-10 h-10 rounded-full bg-red-500 animate-pulse
            ">
              <div className="w-4 h-4 rounded-full bg-white"></div>
            </div>
            <div className="
              absolute top-2 left-1/2 px-2 py-1 rounded-xl shadow-2xl bg-gray-300
              text-xl font-bold font-sans text-gray-800 text-nowrap animate-pulse
            ">
              {description}
            </div>
          </div>
          <img src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png"
               alt="Weather" width={50} height={50} className='animate-bounce'/>
        </Marker>
        );
      })}
    </Map>
  );
}














//

// #search input {
//   min-width: 63px;
//   margin: 8px 0 8px 18px;
//   border: none;
//   background-color: rgb(50, 56, 68);
//   color: rgb(217, 228, 235);
//   transition: 0.2s;
// }
// #search input:focus {
//   box-shadow: none;
//   outline: rgba(128, 165, 233, 0.3) solid 5px;
// }        <form id="search" class="col-10 col-sm-7 col-lg-5 me-md-3 d-flex" style="max-width: 565px" role="search">
//   <input class="form-control rounded-end-0" type="search" placeholder="Search" aria-label="Search">
//     <div class="my-1 my-lg-0" style="height: 56px"></div>
// </form>








//               rotation='' //draggable, pitchAlignment, rotation
//
//       >

//
//       </Marker>
//onLoad={handleMapLoad} onClick={handleMapClick}
//<img width='50' height={50} style={{transform: 'rotateX(90deg) rotateZ(0deg)'}}
//              src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png" alt="Weather"/>

/*import React, { useEffect, useState } from 'react';
import Map, { NavigationControl } from "react-map-gl";
import useGlobalState, { Themes } from "../../contexts/GlobalContext";
import 'mapbox-gl/dist/mapbox-gl.css';



  return (
    <Map
      mapboxAccessToken="pk.eyJ1IjoibmltY290IiwiYSI6ImNtMDVmcmpyNzBocjIyanF4NDVzM2kwYWUifQ.Vk23Zeuc6xh3u1vyY5XO5Q"
      initialViewState={viewport}
      mapStyle={global.theme === Themes.Light ? "mapbox://styles/mapbox/streets-v12" : 'mapbox://styles/mapbox/dark-v11'}
      style={{ width: "100%", height: "100%" }}
    >
<NavigationControl showZoom={true} showCompass={true} visualizePitch={true} position='top-right' />
</Map>
);
};*/
//         projection='mercator'
//          // light
//          // renderWorldCopies
//         // terrain={new TerrainSpecification()}
//       //dragRotate, dragPan, touchPitch, touchZoomRotate, maxPitch,  bearing, pitch, zoom, latitude, longitude
//       // minPitch The minimum pitch of the map (0-85)., minZoom The minimum zoom level of the map (0-24).

//   var bounds = [
//     [-180, -90], // Southwest coordinates
//     [180, 90]    // Northeast coordinates
//   ];
// minZoom={6}
// renderWorldCopies={true}
// maxBounds={bounds}
//      <Marker key='1' latitude={45.4211} longitude={-75.6903}>
//         <img width='50' height={50} src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/map-marker-512.png" alt="Weather"/>
//       </Marker>

/*
* onResize: (event: MapEvent) => void
Called when the map has been resized.

onLoad: (event: MapEvent) => void
Called after all necessary resources have been downloaded and the first visually complete rendering of the map has occurred.

onRender: (event: MapEvent)) => void
Called whenever the map is drawn to the screen.

onIdle: (event: MapEvent)) => void
Called after the last frame rendered before the map enters an "idle" state:

No camera transitions are in progress
All currently requested tiles have loaded
All fade/transition animations have completed
onRemove: (event: MapEvent)) => void
Called when the map has been removed.

onError: (event: ErrorEvent) => void
Default: evt => console.error(evt.error)

Called when an error occurs.

onMouseDown: (event: MapLayerMouseEvent) => void
Called when a pointing device (usually a mouse) is pressed within the map.

If interactiveLayerIds is specified, the event will contain an additional features field that contains features under the cursor from the specified layer.

onMouseUp: (event: MapLayerMouseEvent) => void
Called when a pointing device (usually a mouse) is released within the map.

If interactiveLayerIds is specified, the event will contain an additional features field that contains features under the cursor from the specified layer.

onMouseOver: (event: MapLayerMouseEvent) => void
Called when a pointing device (usually a mouse) is moved within the map. As you move the cursor across a web page containing a map, the event will fire each time it enters the map or any child elements.

onMouseEnter: (event: MapLayerMouseEvent) => void
Called when a pointing device (usually a mouse) enters a visible portion of the layer(s) specified by interactiveLayerIds from outside that layer or outside the map canvas.

onMouseMove: (event: MapLayerMouseEvent) => void
Called when a pointing device (usually a mouse) is moved while the cursor is inside the map. As you move the cursor across the map, the event will fire every time the cursor changes position within the map.

If interactiveLayerIds is specified, the event will contain an additional features field that contains features under the cursor from the specified layer.

onMouseLeave: (event: MapLayerMouseEvent) => void
Called when a pointing device (usually a mouse) leaves a visible portion of the layer(s) specified by interactiveLayerIds or moves from the layer to outside the map canvas.

onMouseOut: (event: MapLayerMouseEvent) => void
Called when a point device (usually a mouse) leaves the map's canvas.

onClick: (event: MapLayerMouseEvent) => void
Called when a pointing device (usually a mouse) is pressed and released at the same point on the map.

If interactiveLayerIds is specified, the event will contain an additional features field that contains features under the cursor from the specified layer.

onDblClick: (event: MapLayerMouseEvent) => void
Called when a pointing device (usually a mouse) is pressed and released twice at the same point on the map in rapid succession.

If interactiveLayerIds is specified, the event will contain an additional features field that contains features under the cursor from the specified layer.

onContextMenu: (event: MapLayerMouseEvent) => void
Called when the right button of the mouse is clicked or the context menu key is pressed within the map.

If interactiveLayerIds is specified, the event will contain an additional features field that contains features under the cursor from the specified layer.

onWheel: (event: MapWheelEvent) => void
Called when a wheel event occurs within the map.

onTouchStart: (event: MapLayerTouchEvent) => void
Called when a touchstart event occurs within the map.

If interactiveLayerIds is specified, the event will contain an additional features field that contains features under the cursor from the specified layer.

onTouchEnd: (event: MapLayerTouchEvent) => void
Called when a touchend event occurs within the map.

If interactiveLayerIds is specified, the event will contain an additional features field that contains features under the cursor from the specified layer.

onTouchMove: (event: MapLayerTouchEvent) => void
Called when a touchmove event occurs within the map.

If interactiveLayerIds is specified, the event will contain an additional features field that contains features under the cursor from the specified layer.

onTouchCancel: (event: MapLayerTouchEvent) => void
Called when a touchcancel event occurs within the map.

If interactiveLayerIds is specified, the event will contain an additional features field that contains features under the cursor from the specified layer.

onMoveStart: (event: ViewStateChangeEvent) => void
Called just before the map begins a transition from one view to another.

onMove: (event: ViewStateChangeEvent) => void
Called repeatedly during an animated transition from one view to another.

When Map is used as a controlled component, event.viewState reflects the view state that the camera "proposes" to move to, as a result of either user interaction or methods such as flyTo. The camera does not actually change until the application updates the view state props (longitude, latitude, zoom etc.). See state management for examples.

onMoveEnd: (event: ViewStateChangeEvent) => void
Called just after the map completes a transition from one view to another.

onDragStart: (event: ViewStateChangeEvent) => void
Called when a "drag to pan" interaction starts.

onDrag: (event: ViewStateChangeEvent) => void
Called repeatedly during a "drag to pan" interaction.

onDragEnd: (event: ViewStateChangeEvent) => void
Called when a "drag to pan" interaction ends.

onZoomStart: (event: ViewStateChangeEvent) => void
Called just before the map begins a transition from one zoom level to another.

onZoom: (event: ViewStateChangeEvent) => void
Called repeatedly during an animated transition from one zoom level to another.

When Map is used as a controlled component, event.viewState.zoom reflects the zoom that the camera "proposes" to change to, as a result of either user interaction or methods such as flyTo. The camera does not actually change until the application updates the zoom prop.

onZoomEnd: (event: ViewStateChangeEvent) => void
Called just after the map completes a transition from one zoom level to another.

onRotateStart: (event: ViewStateChangeEvent) => void
Called just before the map begins a transition from one bearing (rotation) to another.

onRotate: (event: ViewStateChangeEvent) => void
Called repeatedly during an animated transition from one bearing (rotation) to another.

When Map is used as a controlled component, event.viewState.bearing reflects the zoom that the camera "proposes" to change to, as a result of either user interaction or methods such as flyTo. The camera does not actually change until the application updates the bearing prop.

onRotateEnd: (event: ViewStateChangeEvent) => void
Called just after the map completes a transition from one bearing (rotation) to another.

onPitchStart: (event: ViewStateChangeEvent) => void
Called just before the map begins a transition from one pitch (tilt) to another.

onPitch: (event: ViewStateChangeEvent) => void
Called repeatedly during an animated transition from one pitch (tilt) to another.

When Map is used as a controlled component, event.viewState.pitch reflects the zoom that the camera "proposes" to change to, as a result of either user interaction or methods such as flyTo. The camera does not actually change until the application updates the pitch prop.

onPitchEnd: (event: ViewStateChangeEvent) => void
Called just after the map completes a transition from one pitch (tilt) to another.

onBoxZoomStart: (event: MapBoxZoomEvent) => void
Called when a "box zoom" interaction starts.

onBoxZoomEnd: (event: MapBoxZoomEvent) => void
Called when a "box zoom" interaction ends.

onBoxZoomCancel: (event:MapBoxZoomEvent) => void
Called when the user cancels a "box zoom" interaction, or when the bounding box does not meet the minimum size threshold.

onData: (event: MapStyleDataEvent | MapSourceDataEvent) => void
Called when any map data loads or changes. See MapDataEvent for more information.

onStyleData: (event: MapStyleDataEvent) => void
Called when the map's style loads or changes. See MapDataEvent for more information.

onSourceData: (event: MapSourceDataEvent) => void
Called when one of the map's sources loads or changes, including if a tile belonging to a source loads or changes. See MapDataEvent for more information.
*
* */


// Geolocate, search and mark, click, history

/*return (
      <Map
          mapboxAccessToken="pk.eyJ1IjoibmltY290IiwiYSI6ImNtMDVmcmpyNzBocjIyanF4NDVzM2kwYWUifQ.Vk23Zeuc6xh3u1vyY5XO5Q"
          {...viewport}
          mapStyle="mapbox://styles/mapbox/streets-v12"
      />
  );*///{process.env.REACT_APP_MAPBOX_TOKEN}>
/*return (
    <div>
      <Map {...viewport} mapboxAccessToken="pk.eyJ1IjoibmltY290IiwiYSI6ImNtMDVmcmpyNzBocjIyanF4NDVzM2kwYWUifQ.Vk23Zeuc6xh3u1vyY5XO5Q">
        markers here
      </Map>
    </div>
);*/
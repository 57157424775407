import React from 'react';
import useGlobalState, { Themes } from "../../contexts/GlobalContext";
import classes from "./ThemeSwitch.module.sass";

export default () => {
  const {global, updateGlobalState} = useGlobalState();
  const handleThemeChange = (): void => {
    let newTheme: Themes = global.theme === Themes.Dark ? Themes.Light : Themes.Dark;
    updateGlobalState('theme', newTheme);
    localStorage.setItem('current-theme', newTheme);
  };
  return (
    <div className={`${classes.themeSwitch} px-3 flex items-center`}>
      <input id="theme-switch" className='hidden' type="checkbox"
              checked={global.theme === Themes.Dark} onChange={handleThemeChange}/>
      <label htmlFor="theme-switch"
             className={`${classes.themeSwitchBackground} rounded-full shadow-inner flex py-px px-[2px]`}>
        <span className={`${classes.themeSwitchIcon} aspect-square h-full rounded-full shadow-inner`}/>
      </label>
    </div>
  );
}
import { PropsWithChildren, useState } from 'react';
import { useHistory } from 'react-router-dom';
import classes from "./NavButton.module.sass";

interface NavButtonProps extends PropsWithChildren {
  icon: any,
  largerText?: boolean,
  onClick?: (() => void) | string
}

export default ({ icon, largerText, onClick, children }: NavButtonProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const handleClick = () =>
    onClick ? (typeof onClick === 'string' ? history.push(onClick) : onClick()) : setOpen(!isOpen);
  return (
    <div className='relative flex items-center'>
      <button
        onClick={handleClick}
        className={
          `${classes.navButton} flex items-center justify-center pb-0.5 rounded-full
           ${largerText ? 'text-3xl' : 'text-xl'}`
        }
      >
        {icon}
      </button>
      { isOpen && children }
    </div>
  )
};
import Layout from "../components/Layout";
import Header from "../components/Header";
import TitleBar from "../components/TitleBar/TitleBar";
import NavBar from "../components/NavBar/NavBar";
import ByteDawnIcon from "../components/ByteDawnIcon/ByteDawnIcon";
import NavButton from "../components/NavButton/NavButton";
import ThemeSwitch from "../components/ThemeSwitch/ThemeSwitch";
import ScrollableContainer from "../components/ScrollableContainer/ScrollableContainer";
import AuthBox from "../components/AuthBox/AuthBox";
import Footer from "../components/Footer/Footer";
import FooterSection from "../components/FooterSection/FooterSection";
import AnimatedLink from "../components/AnimatedLink/AnimatedLink";

export default () =>
  <Layout title='Sign In'>
    <Header>
      <TitleBar/>
      <NavBar>
        <ByteDawnIcon/>
        <NavButton icon='⇱' onClick='/' center/>
        <ThemeSwitch end/>
      </NavBar>
    </Header>
    <ScrollableContainer>
      <main>
        <AuthBox/>
      </main>
      <Footer>
        <FooterSection title='About Tempest' paragraph>
          🗨️ Tempest is a next level React/SASS/Lambda mapping service hosted on AWS
        </FooterSection>
        <FooterSection title='Services'>
          <AnimatedLink to='/'>Home</AnimatedLink>
        </FooterSection>
      </Footer>
    </ScrollableContainer>
  </Layout>
import React from 'react';
import FooterSection from "../FooterSection/FooterSection";
import classes from "./Footer.module.sass";

interface FooterProps {
  children?: React.ReactElement<typeof FooterSection>[]
}

export default ({ children }: FooterProps): JSX.Element =>
  <footer className={`${classes.footer} grid grid-cols-1 md:grid-cols-2 py-4 px-20`}>
    {children}
  </footer>
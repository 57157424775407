import React from 'react';
import classes from "./FooterSection.module.sass";

interface SectionProps {
  title: string,
  paragraph?: boolean,
  children?: React.ReactNode[]
}

export default ({ title, paragraph, children }: SectionProps): JSX.Element =>
  <div className={`${paragraph ? 'w-fit' : 'w-32'} mt-7 md:mx-auto mb-12`}>
    <h3 className={`${classes.title} mb-5 text-lg uppercase text-nowrap pointer-events-none`}>{title}</h3>
    <ul>{React.Children.toArray(children).map(item => <li className='mt-3'>{item}</li>)}</ul>
  </div>
import { useState, createContext, useContext, PropsWithChildren, ReactElement } from 'react';
import classes from "*.module.sass";

// [Possible values]

export const enum Themes {
  Light = 'light',
  Dark = 'dark'
}

export const enum Languages {
  English = 'en',
  German = 'ge',
  Russian = 'ru'
}

// [Interfaces]

interface GlobalStates {
  isAuthorized: boolean;
  isLoading: boolean;
  theme: Themes;
  lang: Languages;
  visitedPlaces: { latitude: number, longitude: number, description: string }[]
}

interface GlobalContextFields {
  global: GlobalStates;
  updateGlobalState: (key: keyof GlobalStates, newValue:
    boolean | Themes | Languages | { latitude: number, longitude: number, description: string }) => void;
}

// [Default states]

const defaultGlobalStates: GlobalStates = {
  isAuthorized: false,
  isLoading: true,
  theme: localStorage.getItem('current-theme') as Themes ||
    (window.matchMedia('(prefers-color-scheme: dark)').matches ? Themes.Dark : Themes.Light),
  lang: Languages.English,
  visitedPlaces: []
};

const GlobalContext = createContext<GlobalContextFields | undefined>(undefined);

// [Interaction]

export function WithGlobalState(props: PropsWithChildren): ReactElement {
    const [global, setGlobalState] = useState<GlobalStates>(defaultGlobalStates);
    const updateGlobalState = (key: keyof GlobalStates, newValue: boolean | Themes | Languages | Object) =>
      setGlobalState(oldState => {
        console.log('Assign', newValue, Array.isArray(newValue));
        if (typeof newValue === 'object') {
          if (newValue === null) {
            return { ...oldState, [key]: [] }
          } else {
            let x = oldState[key];
            // @ts-ignore
            x.push(newValue);
            return { ...oldState, [key]: x }
          }
        }
        return oldState[key] !== newValue ? { ...oldState, [key]: newValue } : oldState;
      });
    return <GlobalContext.Provider value={{global, updateGlobalState}}>{props.children}</GlobalContext.Provider>
}

export default function useGlobalState(): GlobalContextFields {
  const context = useContext(GlobalContext);
  if (!context)
    throw new Error('useGlobalState must be used within a WithGlobalState provider');
  return context;
}
import $ from 'jquery';
import { useState } from 'react';
import axios from 'axios';
import classes from "./AuthBox.module.sass";
import {useHistory} from "react-router-dom";
import useGlobalState from "../../contexts/GlobalContext";
import { setCookie } from "../../services/CookiesService";

const checkUserAPIURL = "https://j4g4ad57h0.execute-api.eu-north-1.amazonaws.com/prod/user";
const checkEmailAPIURL = "https://j4g4ad57h0.execute-api.eu-north-1.amazonaws.com/prod/email";
const loginAPIURL = "https://j4g4ad57h0.execute-api.eu-north-1.amazonaws.com/prod/login";
const registerAPIURL = 'https://j4g4ad57h0.execute-api.eu-north-1.amazonaws.com/prod/register';
const config = {
  headers: {
    'x-api-key': 'tXm34puc5n6mP0JrNgRVZ3rD8P396WKx3vepLVSf',
    'Access-Control-Allow-Origin': '*'
  }
};

export default () => {
  const [userExists, setUserExists] = useState(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  let inputBlurHandler = async event => {
    let input = event.target, color, cursor, events;
    switch (input.id) {
      case 'username': {
        if (username.length === 0) {
          color = '#4793c0';
          cursor = 'default';
          events = 'none';
        } else if (/^[a-zA-Z][a-zA-Z0-9_\-]*$/.test(username)) {
          color = '#9fb267';
          cursor = 'pointer';
          events = 'initial';
        } else {
          color = '#d33333';
          cursor = 'default';
          events = 'none';
        }
        $('#username ~ label').css({'color': color, 'cursor': cursor, 'pointer-events': events});
        $('#username ~ b').css('color', color);
        break;
      }
      case 'email': {
        if (email.length === 0) {
          color = '#4793c0';
          cursor = 'default';
          events = 'none';
        } else if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
          let taken = true;
          await axios.post(checkEmailAPIURL, {email: email}, config).then(response => {
            taken = response.data.exists;
          }).catch(() => {
            taken = true;
          })
          if (taken) {
            color = '#d33333';
            cursor = 'default';
            events = 'none';
          } else {
            color = '#9fb267';
            cursor = 'pointer';
            events = 'initial';
          }
        } else {
          color = '#d33333';
          cursor = 'default';
          events = 'none';
        }
        $('#email ~ label').css({'color': color, 'cursor': cursor, 'pointer-events': events});
        $('#email ~ b').css('color', color);
        break;
      }
      case 'password1': {
        if (password1.length === 0) {
          color = '#4793c0';
          cursor = 'default';
          events = 'none';
        } else if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9_\-]{8,}$/.test(password1)) {
          let correct = true;
          if (userExists) {
            await axios.post(loginAPIURL, {username: username, password: password1}, config).then(response => {
              correct = true;
            }).catch(() => {
              correct = false;
            })
          }
          if (correct) {
            color = '#9fb267';
            cursor = 'pointer';
            events = 'initial';
          } else {
            color = '#d33333';
            cursor = 'default';
            events = 'none';
          }
        } else {
          color = '#d33333';
          cursor = 'default';
          events = 'none';
        }
        $('#password1 ~ label').css({'color': color, 'cursor': cursor, 'pointer-events': events});
        $('#password1 ~ b').css('color', color);
        break;
      }
      case 'password2': {
        if (password2.length === 0) {
          color = '#4793c0';
          cursor = 'default';
          events = 'none';
        } else if (password1 === password2) {
          color = '#9fb267';
          cursor = 'pointer';
          events = 'initial';
        } else {
          color = '#d33333';
          cursor = 'default';
          events = 'none';
        }
        $('#password2 ~ label').css({'color': color, 'cursor': cursor, 'pointer-events': events});
        $('#password2 ~ b').css('color', color);
        break;
      }
    }
  }
  const history = useHistory();
  let arrowClickHandler = event => {
    event.preventDefault();
    switch ($(event.target).parent().parent().find('input').attr('id')) {
      case 'username': {
        $('#auth-username-group').css({'visibility': 'hidden', 'opacity': '0'});
        $('#auth-username-group b').css('width', '0');
        axios.post(checkUserAPIURL, {username: username}, config).then(response => {
          setUserExists(response.data.exists);
          if (response.data.exists) {
            $('#auth-password1-group').css({'visibility': 'visible', 'opacity': '1'});
            $('#auth-password1-group b').css('width', '100%');
          } else {
            $('#auth-email-group').css({'visibility': 'visible', 'opacity': '1'});
            $('#auth-email-group b').css('width', '100%');
          }
        }).catch(() => {
          history.push('/auth');
        });
        break;
      }
      case 'email': {
        $('#auth-email-group').css({'visibility': 'hidden', 'opacity': '0'});
        $('#auth-email-group b').css('width', '0');
        $('#auth-password1-group').css({'visibility': 'visible', 'opacity': '1'});
        $('#auth-password1-group b').css('width', '100%');
        break;
      }
      case 'password1': {
        $('#auth-password1-group').css({'visibility': 'hidden', 'opacity': '0'});
        $('#auth-password1-group b').css('width', '0');
        if (userExists) {
          $('#password1').prop('disabled', true);
          submitHandler();
        } else {
          $('#auth-password2-group').css({'visibility': 'visible', 'opacity': '1'});
          $('#auth-password2-group b').css('width', '100%');
        }
        break;
      }
      case 'password2': {
        $('#password1').prop('disabled', true);
        $('#auth-password2-group').css({'visibility': 'hidden', 'opacity': '0'});
        $('#auth-password2-group b').css('width', '0');
        submitHandler();
        break;
      }
    }
  }

  const {updateGlobalState} = useGlobalState();
  let submitHandler = async () => {
    updateGlobalState('isLoading', true);
    if (!userExists) {
      await axios.post(registerAPIURL, {
        username: username,
        email: email,
        password: password1
      }, config).then(response => {
        sessionStorage.setItem("user", username);
        sessionStorage.setItem("jwt-token", response.data.token);
        document.cookie = setCookie("user", username, {expires: 1});
        document.cookie = setCookie("jwt-token", response.data.token, {expires: 1});
      }).catch(() => {
        history.push('/auth');
      })
    } else {
      await axios.post(loginAPIURL, {username: username, email: email, password: password1}, config).then(response => {
        sessionStorage.setItem("user", username);
        sessionStorage.setItem("jwt-token", response.data.token);
        document.cookie = setCookie("user", username, {expires: 1});
        document.cookie = setCookie("jwt-token", response.data.token, {expires: 1});
      }).catch(() => {
        history.push('/auth');
      })
    }
    updateGlobalState('isLoading', false);
    updateGlobalState('isAuthorized', true);
    history.push('/tempest');
  }
  return (
    <div className='overflow-hidden grid place-items-center h-full p-36'>
      <input id="auth-start-toggle" type="checkbox" className={classes.startToggle}/>
      <div className='flex justify-center w-44 h-20'>
        <form className='shadow-lg relative w-[inherit] h-[inherit]'>
          <label htmlFor="auth-start-toggle"
                 className='flex items-center justify-center w-[inherit] h-[inherit] text-xl cursor-pointer'>
            Authorize
          </label>
          <div id="auth-username-group" className={`${classes.dataGroup} ${classes.usernameGroup}`}>
            <label htmlFor="username"
                   className={`${classes.inputLabel} relative flex items-end h-full cursor-pointer`}>
              <input id="username" name="username" type="text" className={classes.dataInput} placeholder=" " required
                     data-toggle="tooltip" data-placement="bottom"
                     onBlur={inputBlurHandler} onChange={event => setUsername(event.target.value.trim())}
                     title="Username should be free and 6 to 16 characters in length: letters, numbers, '_', '-', starting from a letter"/>
              <label className={classes.submitArrow} role="button" onClick={arrowClickHandler}>
                <span className='text-xl'>➤</span>
              </label>
              <span className={classes.inputHint}>What is your desired username?</span>
              <b className={classes.inputUnderline}></b>
            </label>
          </div>
          <div id="auth-email-group" className={classes.dataGroup}>
            <label htmlFor="email"
                   className={`${classes.inputLabel} relative flex items-end h-full cursor-pointer`}>
              <input id="email" name="email" type="text" className={classes.dataInput} placeholder=" " required
                     data-toggle="tooltip" data-placement="bottom"
                     onBlur={inputBlurHandler} onChange={event => setEmail(event.target.value.trim())}
                     title="Email is either taken or not an email notation"/>
              <label className={classes.submitArrow} role="button" onClick={arrowClickHandler}>
                <span>➤</span>
              </label>
              <span className={classes.inputHint}>What is your email?</span>
              <b className={classes.inputUnderline}></b>
            </label>
          </div>
          <div id="auth-password1-group" className={classes.dataGroup}>
            <label htmlFor="password1"
                   className={`${classes.inputLabel} relative flex items-end h-full cursor-pointer`}>
              <input id="password1" type="text" className={classes.dataInput} placeholder=" " required
                     data-toggle="tooltip" data-placement="bottom"
                     onBlur={inputBlurHandler} onChange={event => setPassword1(event.target.value.trim())}
                     title="Password is 8 characters at least: contains minimum 1 small letter, 1 capital and 1 number"/>
              <label className={classes.submitArrow} role="button" onClick={arrowClickHandler}>
                <span>➤</span>
              </label>
              <span className={classes.inputHint}>What is your password?</span>
              <b className={classes.inputUnderline}></b>
            </label>
          </div>
          <div id="auth-password2-group" className={classes.dataGroup}>
            <label htmlFor="password2"
                   className={`${classes.inputLabel} relative flex items-end h-full cursor-pointer`}>
              <input id="password2" type="text" className={classes.dataInput} placeholder=" " required
                     data-toggle="tooltip" data-placement="bottom"
                     onBlur={inputBlurHandler} onChange={event => setPassword2(event.target.value.trim())}
                     title="Passwords do not match"/>
              <label className={classes.submitArrow} role="button" onClick={arrowClickHandler}>
                <span>➤</span>
              </label>
              <span className={classes.inputHint}>Please confirm your password</span>
              <b className={classes.inputUnderline}></b>
            </label>
          </div>
        </form>
      </div>
    </div>
  );
}